import {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import {
  PageResultsOption,
  TableFilter,
  initTableStateProps,
} from '../../../@types/Table';
import { useNavigate } from 'react-router-dom';
import generatePaginationNumbers from '../../../utils/genPaginationNumber';
import {
  SortObject,
  parseSorting,
  stringifySorting,
} from '../../../utils/sortingParser';

import { pageSelectOptions } from '../../../mock/PageSelection';
import { useAuth } from '../../auth';
import { Grid, TableProps } from 'antd';
import { EmployeeSimple } from '../../../@types/Employee';
const { useBreakpoint } = Grid
interface TableContextProps {
  children: ReactNode;
  hasStatus?: boolean;
  hasView?: boolean;
  hasType?: boolean;
  data?: Array<
    any & {
      LeaveBalance?: { Employee: { Person: { Id: number } } };
      Request?: { LeaveBalance?: { Employee: { Person: { Id: number } } } };
    }
  >;
  setData?: (val: any[]) => void;
  actions?: Array<(val: any) => void>;
  totalSizeData: number;
  filters: { Id: number; label: string; value: boolean }[];
  page?: { value: number; label: string };
  start?: Date | string | undefined,
  end?: Date | string | undefined,
  defaultSort?: SortObject[]
  types?: { Id: number, value: boolean, label: string; }[];
}

const initTableState: initTableStateProps = {
  typefilter: undefined,
  type: '',
  handleTableChange: () => { },
  handleReset: () => { },
  handleCheckboxChange: () => { },
  handleChangePageSize: () => { },
  handleOrgFilter: () => { },
  searchTerm: '',
  handleSearch: () => { },
  initFilterOptions: [],
  isLoading: true,
  pageSize: { label: '', value: 10 },
  pageIndex: 0,
  handlePaginate: () => { },
  paginNumbers: [],
  currentPage: 1,
  filterStates: [],
  status: '0,1,2,3',
  setIsLoading: () => { },
  from: '',
  to: '',
  sort: [],
  handleSort: () => { },
  handleFromChange: () => { },
  handleToChange: () => { },
  orgId: 0,
  totalSize: 0,
  handleTypeChange: () => { },
  handleDate: () => { },
  filterByEmployee: () => { },
  filterPeople: [],
  employeeIds: [],
  setFilterPeople: () => { },
  handleRangeDate: () => { },
  setEmployeeIds: () => { },
  meMode: false,
  setMeMode: () => { },
  handleMeMode: () => { },
  view: 'dayGridMonth',
  setView: () => { },
  handleViewChange: () => { },
  filterTeam: () => { }
};

const TableContext = createContext(initTableState);
TableContext.displayName = 'Timesheet Table';

export const initFilterOptions: TableFilter[] = [
  { Id: 0, label: 'Draft', value: true },
  { Id: 1, label: 'Submitted', value: true },
  { Id: 2, label: 'Approved', value: true },
  { Id: 7, label: 'Rejected', value: true },
];

export const TableProvider = ({
  children,
  totalSizeData,
  filters,
  page,
  data,
  actions,
  start, end,
  defaultSort = [],
  types,
  hasStatus = true,
  hasView = false,
  hasType = false,
}: TableContextProps) => {
  const navigate = useNavigate();
  const { id } = useAuth();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState('0,1,2,3');
  const [type, setType] = useState<string>('0,1');
  const [typefilter, setTypeFilter] = useState<{ Id: number, label: string, value: boolean }[] | undefined>(types);

  const [pageIndex, setPageIndex] = useState(0);
  const [paginNumbers, setPaginNumbers] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { xs } = useBreakpoint();
  const [from, setFrom] = useState<string>(() => {
    if (typeof start === 'string') {
      return start;
    } else if (start instanceof Date) {
      return start.toISOString();
    } else {
      return '';
    }
  });
  const [to, setTo] = useState<string>(() => {
    if (typeof end === 'string') {
      return end;
    } else if (end instanceof Date) {
      return end.toISOString();
    } else {
      return '';
    }
  });
  const [sort, setSort] = useState<SortObject[]>(defaultSort);
  const [filterPeople, setFilterPeople] = useState<EmployeeSimple[]>([]);
  const [pageSize, setPageSize] = useState<PageResultsOption>(
    page ? page : pageSelectOptions[0],
  );
  const [employeeIds, setEmployeeIds] = useState<string[]>([]);
  const [orgId, setOrgId] = useState<number>(0);
  const [filterStates, setFilterStates] = useState<TableFilter[]>(filters);
  const [meMode, setMeMode] = useState(false);
  const [view, setView] = useState<'dayGridWeek' | 'dayGridDay' | 'dayGridMonth' | 'customFourWeeks'
  >(xs ? 'dayGridDay' : 'dayGridMonth')
  useEffect(() => {
    setPaginNumbers(
      generatePaginationNumbers(
        pageSize?.value ?? 10,
        pageIndex,
        totalSizeData,
      ),
    );
  }, [totalSizeData]);
  useEffect(() => {
    if (xs) {
      setView('dayGridDay')
    }
  }, [xs])
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.size) {
      const parsedPageSelect: PageResultsOption = pageSelectOptions.find(
        (pageSizeOption) =>
          pageSizeOption.value === Number(searchParams.get('pageSize')),
      ) || { value: Number(searchParams.get('pageSize')), label: 'get' };

      const parsedStatus = filterStates.map((filterState) => ({
        ...filterState,
        value: String(searchParams.get('status')).includes(String(filterState.Id))
          ? true
          : false,
      }));
      const parsedTypes = types?.map((t, index) => ({
        ...t,
        value: String(searchParams.get('type')).includes(String(index)) ? true : false
      }))
      setEmployeeIds(String(searchParams.get('employeeId')).split(','));
      setCurrentPage(Number(searchParams.get('pageIndex')) + 1);
      setSearchTerm(String(searchParams.get('searchTerm') !== null ? searchParams.get('searchTerm') : ''));
      setPageIndex(Number(searchParams.get('pageIndex')));
      setStatus(String(searchParams.get('status')));
      setFrom(String(searchParams.get('from')));
      setTo(String(searchParams.get('to')));
      setSort(
        String(searchParams.get('sorting')) !== undefined
          ? parseSorting(String(searchParams.get('sorting')))
          : sort,
      );
      setOrgId(Number(searchParams.get('orgId')));
      setPageSize(parsedPageSelect);
      setFilterStates(parsedStatus.length > 0 ? parsedStatus : filters);
      setTypeFilter(parsedTypes !== undefined && parsedTypes?.length > 0 ? parsedTypes : types);
      setType(String(searchParams.get('type')));
      setPaginNumbers(
        generatePaginationNumbers(
          Number(searchParams.get('pageSize')),
          Number(searchParams.get('pageIndex')),
          totalSizeData,
        ),
      );
      setOrgId(Number(searchParams.get('orgId')));
      setMeMode(String(searchParams.get('meMode')) === 'true');
      setEmployeeIds(String(searchParams.get('personIds')).split(',').filter((em) => em !== '').length === 0 ? [] : String(searchParams.get('personIds')).split(',').filter((em) => em !== ''));
      // @ts-ignore
      setView(hasView && String(searchParams.get('view')));
      const params = {
        orgId: String(searchParams.get('orgId')) === 'null' ? '0' : String(searchParams.get('orgId')),
        searchTerm: searchParams.get('searchTerm') !== null ? String(searchParams.get('searchTerm')) : '',
        pageIndex: Number(searchParams.get('pageIndex')),
        pageSize: Number(searchParams.get('pageSize')),
        status: String(searchParams.get('status')),
        from: String(searchParams.get('from')),
        to: String(searchParams.get('to')),
        type: String(searchParams.get('type')),
        sorting: String(searchParams.get('sorting')) === 'null' ? '' : String(searchParams.get('sorting')),
        personIds: String(searchParams.get('personIds')),
      };
      const actionPromises = (actions || []).map(action => action(params));
      Promise.all(actionPromises)
        .then((results) => {
          // Handle success for both actions here if needed
          console.log('Both actions completed successfully:', results);
        })
        .catch((error) => {
          // Handle errors here if any of the actions failed
          console.error('Error executing actions:', error);
        });

    } else {
      setCurrentPage(1);
      setSearchTerm('');
      setPageIndex(0);
      const tmp = filterStates
        .map((state) => (state.value ? state.Id : null)) // Map to index or null
        .filter((value) => value !== null);
      const typetmp = types?.map((state, index) => (state.value ? index : null)).filter((value) => value !== null);
      setStatus(tmp.join(','));
      setType(typetmp !== undefined ? typetmp?.join(',') : '0,1')
      setSort(sort);
      setOrgId(0);
      setPaginNumbers(generatePaginationNumbers(10, 0, totalSizeData));
      setPageSize(page ? page : pageSelectOptions[0]);
      setFilterStates(filters);
      setTypeFilter(types);
      setEmployeeIds([]);
      setView(view)
      const params = {
        status: tmp.length > 0 ? tmp.join(',') : status,
        from: start ? start : '',
        to: end ? end : '',
        pageSize: pageSize.value,
      };
      const actionPromises = (actions || []).map(action => action(params));
      Promise.all(actionPromises)
        .then((results) => {
          // Handle success for both actions here if needed
          console.log('Both actions completed successfully:', results);
        })
        .catch((error) => {
          // Handle errors here if any of the actions failed
          console.error('Error executing actions:', error);
        });


    }
  }, [location.search]);

  const getUrl = ({
    _searchTerm,
    _pageIndex,
    _pageSize,
    _status,
    _from,
    _to,
    _sort,
    _orgId,
    _personIds,
    _meMode,
    _view,
    _type,
  }: {
    _searchTerm?: string | number;
    _pageIndex?: number;
    _pageSize?: number;
    _status?: string;
    _from?: string;
    _to?: string;
    _sort?: string;
    _orgId?: number;
    _personIds?: string[];
    _meMode?: boolean;
    _view?: string;
    _type?: string;
  }): string => {
    const queryParams: string[] = [];

    // Add parameters to the array only if they have a value
    if (_searchTerm || searchTerm) queryParams.push(`searchTerm=${_searchTerm ?? searchTerm}`);
    if (_pageIndex || currentPage !== undefined) queryParams.push(`pageIndex=${_pageIndex ?? currentPage}`);
    if (_pageSize || pageSize?.value !== undefined) queryParams.push(`pageSize=${_pageSize ?? pageSize?.value}`);
    if ((_status || status !== undefined) && hasStatus) queryParams.push(`status=${_status ?? status}`);
    queryParams.push(`from=${_from ?? from}`);
    queryParams.push(`to=${_to ?? to}`);
    if (_sort || sort) queryParams.push(`sorting=${_sort ?? stringifySorting(sort)}`);
    if (_orgId || orgId !== undefined) queryParams.push(`orgId=${_orgId ?? orgId}`);
    if (_personIds || employeeIds.length > 0 || employeeIds !== null) queryParams.push(`personIds=${_personIds ?? employeeIds.join(',')}`);
    if (_meMode || meMode !== undefined) queryParams.push(`meMode=${_meMode ?? meMode}`);
    if ((_view || view) && hasView) queryParams.push(`view=${_view ?? view}`);
    if ((_type || type) && hasType) queryParams.push(`type=${_type ?? type}`);

    // Join the query parameters with '&' and prepend with '?' to form the final query string
    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
    return queryString
  }

  const filterTeam = (employees: string[], dates?: Date[]) => {
    if (data && employees.length > 0) {
      navigate(
        getUrl({
          _pageIndex: 0,
          _pageSize: pageSize?.value,
          _status: status,
          _searchTerm: searchTerm,
          _sort: sort && stringifySorting(sort),
          _from: dates && dates[0].toISOString(),
          _to: dates && dates[1].toISOString(),
          _personIds: [...new Set(employees)],
          _meMode: false
        }),
      );
    } else {
      setEmployeeIds([]);
      navigate(
        getUrl({
          _pageIndex: 0,
          _pageSize: pageSize?.value,
          _status: status,
          _searchTerm: searchTerm,
          _sort: sort && stringifySorting(sort),
          _from: dates && dates[0].toISOString(),
          _to: dates && dates[1].toISOString(),
          _personIds: [],
          _meMode: false
        }),
      );
    }
  }
  const filterByEmployee = (employees: string[], dates?: Date[]) => {
    if (data && employees.length > 0) {
      navigate(
        getUrl({
          _pageIndex: 0,
          _pageSize: pageSize?.value,
          _status: status,
          _searchTerm: searchTerm,
          _sort: sort && stringifySorting(sort),
          _from: dates && dates[0].toISOString(),
          _to: dates && dates[1].toISOString(),
          _personIds: [...new Set(employees.filter((emp) => emp !== String(id)))],
          _meMode: false
        }),
      );
    } else {
      setEmployeeIds([]);
      navigate(
        getUrl({
          _pageIndex: 0,
          _pageSize: pageSize?.value,
          _status: status,
          _searchTerm: searchTerm,
          _sort: sort && stringifySorting(sort),
          _from: dates && dates[0].toISOString(),
          _to: dates && dates[1].toISOString(),
          _personIds: [],
          _meMode: false
        }),
      );
    }
  };
  const handleTableChange: TableProps['onChange'] = (
    pagination,
    filters,
    sorter,
  ) => {
    let tmp: SortObject[] | [] = []
    if (Array.isArray(sorter)) {
      tmp = sorter.map((sort) => {
        return {
          key: String(sort.columnKey),
          value: sort.order === 'ascend' ? 'asc' : 'desc',
        };
      });

    } else {
      if (sorter?.['order'] === undefined) {
        tmp = []
      } else {
        tmp = [{
          key: String(sorter.columnKey),
          value:
            String(sorter.order) === 'ascend'
              ? 'asc'
              : String(sorter.order) === 'descend'
                ? 'desc'
                : '',
        },]
      }
    }
    if (pagination.current !== currentPage && pagination.current) {

      handlePaginate(pagination.current - 1, tmp);
      return;
    }
    if (pagination.pageSize !== pageSize?.value && pagination.pageSize) {
      handleChangePageSize({
        label: String(pagination.pageSize),
        value: pagination.pageSize,
      });
      return;
    }
    if (Array.isArray(sorter)) {
      const tmp = sorter.map((sort) => {
        return {
          key: String(sort.columnKey),
          value: sort.order === 'ascend' ? 'asc' : 'desc',
        };
      });
      handleSort(tmp);
    } else {
      handleSort(
        sorter?.['order'] === undefined
          ? []
          : [
            {
              key: String(sorter.columnKey),
              value:
                String(sorter.order) === 'ascend'
                  ? 'asc'
                  : String(sorter.order) === 'descend'
                    ? 'desc'
                    : '',
            },
          ],
      );
    }
  };
  const handleViewChange = (v: any) => {
    setView(v);
    navigate(getUrl({ _pageIndex: 0, _view: v }))
  }
  const handleReset = () => {
    navigate(getUrl({ _pageIndex: 0, _from: '', _to: '', _meMode: false, _status: '', _orgId: 0, _pageSize: 10, _searchTerm: '', _personIds: [], _sort: '' }))
  }
  const handleDate = async (dates: Date[], v?: string) => {
    const timezoneOffset = dates[1].getTimezoneOffset() * 60000;
    const adjustedDate = new Date(dates[1].getTime() - timezoneOffset);
    const localISODate = adjustedDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
    navigate(
      getUrl({
        _pageIndex: 0,
        _pageSize: pageSize?.value,
        _status: status,
        _sort: sort && stringifySorting(sort),
        _searchTerm: searchTerm,
        _from: dates[0].toISOString(),
        _to: localISODate,
        _view: v,
        _meMode: meMode
      }),
    );
  };
  const handleMeMode = async (v: boolean) => {
    setMeMode(v);
    if (v && id) {
      navigate(
        getUrl({
          _pageIndex: 0,
          _pageSize: pageSize?.value,
          _status: status,
          _sort: sort && stringifySorting(sort),
          _searchTerm: searchTerm,
          _personIds: [id.toString()],
          _meMode: true,
        }),
      );
    } else {
      navigate(
        getUrl({
          _pageIndex: 0,
          _pageSize: pageSize?.value,
          _status: status,
          _sort: sort && stringifySorting(sort),
          _searchTerm: searchTerm,
          _personIds: [],
          _meMode: false,
        }),
      );
    }
  }
  const handleCheckboxChange = (index: number) => {
    const getIndexString = (filterOptions: TableFilter[]) =>
      filterOptions
        .map((option) => (option.value ? option.Id.toString() : null))
        .filter((index) => index !== null)
        .toString();
    const statusStates = filterStates.map((filterState, i) =>
      index === filterState.Id ? { ...filterState, value: !filterState.value } : filterState,
    );
    if (statusStates.filter((st) => st.value).length > 0)
      navigate(getUrl({ _pageIndex: 0, _status: getIndexString(statusStates) }));
    else
      navigate(getUrl({ _pageIndex: 0, _status: '5' }));
  };
  const handleTypeChange = (index: number[]) => {
    const getIndexString = (filterOptions: TableFilter[]) =>
      filterOptions
        .map((option, idx) => (option.value ? idx.toString() : null))
        .filter((idx) => idx !== null)
        .toString();

    // Create a new array to hold updated filter options
    if (typefilter) {
      const updatedFilters = [...typefilter];

      // Set all filters to `false` initially
      updatedFilters.forEach((filter) => {
        filter.value = false;
      });

      // Loop through the input `index` array to set the `value` based on the input index
      index.forEach((i) => {
        // Check if the index is within bounds and set the value to `true`
        if (updatedFilters[i]) {
          updatedFilters[i].value = true;
        }
      });
      // Convert the updated filter array to the string representation for URL parameter
      const typeIndexString = getIndexString(updatedFilters);

      // Navigate with the updated URL parameter
      navigate(getUrl({ _pageIndex: 0, _type: typeIndexString }));
    }

  };

  const handleSearch = (value: string | number) =>
    navigate(getUrl({ _searchTerm: value, _pageIndex: 0 }));
  const handleOrgFilter = (orgId: number) => {
    navigate(getUrl({ _pageIndex: 0, _orgId: orgId }));
  };
  const handleRangeDate = (values: string[]) => {
    navigate(getUrl({ _from: values[0] ? values[0] : '', _to: values[1] ? values[1] : '', _pageIndex: 0 }));
  }

  const handleChangePageSize = (e: PageResultsOption) =>
    navigate(getUrl({ _pageSize: e?.value, _pageIndex: 0 }));
  const handleFromChange = (value: string) =>
    navigate(getUrl({ _from: value, _pageIndex: 0 }));
  const handleToChange = (value: string) =>
    navigate(getUrl({ _to: value, _pageIndex: 0 }));
  const handleSort = (val: SortObject[] | []) => {
    if (val.length === 0) {
      navigate(getUrl({ _pageIndex: pageIndex }));
    }
    navigate(getUrl({ _sort: stringifySorting(val), _pageIndex: 0 }));
  };
  const handlePaginate = (
    action: 'next' | 'prev' | 'first' | 'last' | number,
    val?: SortObject[] | []
  ) => {
    let goTo: number;

    switch (action) {
      case 'next':
        goTo = Math.min(pageIndex + 1, totalSizeData);
        break;

      case 'prev':
        goTo = Math.max(pageIndex - 1, 0);
        break;

      case 'first':
        goTo = 0;
        break;

      case 'last':
        goTo = Math.floor(totalSizeData / Number(pageSize?.value));
        break;

      default:
        goTo = action;
        break;
    }

    navigate(
      getUrl({ _pageIndex: goTo, _sort: val ? stringifySorting(val) : '' }),
    );
  };

  return (
    <TableContext.Provider
      value={{
        handleTypeChange,
        typefilter,
        meMode,
        handleMeMode,
        setMeMode,
        filterByEmployee,
        filterPeople,
        setFilterPeople,
        setIsLoading,
        isLoading,
        searchTerm,
        pageSize,
        pageIndex,
        paginNumbers,
        currentPage,
        filterStates,
        status,
        view,
        setView,
        handleViewChange,
        initFilterOptions,
        handlePaginate,
        handleSearch,
        handleChangePageSize,
        handleCheckboxChange,
        from,
        to,
        handleToChange,
        handleFromChange,
        sort,
        handleSort,
        handleRangeDate,
        handleOrgFilter,
        orgId,
        totalSize: totalSizeData,
        handleDate,
        employeeIds,
        setEmployeeIds,
        handleTableChange,
        filterTeam,
        type,
        handleReset
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

const useTableContext = () => useContext(TableContext);

export default useTableContext;
