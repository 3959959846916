import axios from 'axios';
import envConfig from '../../enviroment/enviroment';
import { CommonListResponse } from '../@types/Response';
import { Meeting, MeetingListSchema } from '../@types/Meeting';



const API_URL = envConfig.getEnvKey('APP_DEV_URL');
export const CREATE_MEETING = `${API_URL}/meetings`;
export const COUNT_MEETING = `${API_URL}/meetings/count`;
export const GET_MEETING_LIST = `${API_URL}/meetings/list`;
export const CREATE_TRIP_FROM_MEETING = `${API_URL}/trips/create-from-meeting`



export const createMeeting = async (data: Meeting) => {
    try {
        const response = await axios.post<''>(CREATE_MEETING, data);
        return response.data;
    } catch (error) {
        console.error('Error creating leave:', error);
        throw error;
    }
};

export const fetchMeetings = async (
    {
        pageIndex = 0,
        pageSize = 10,
        searchTerm = '',
        status = '',
        from = '',
        to = '',
        sorting = 'From:desc',
        personIds = '',
    }
) => {
    const params = new URLSearchParams({
        pageSize: pageSize?.toString(),
        pageIndex: pageIndex?.toString(),
        searchTerm,
        sorting,
        status,
        from,
        to,
        personIds,
    });
    const response = await axios.get<CommonListResponse<Meeting>>(
        `${GET_MEETING_LIST}?${params.toString()}`,
    );
    // await MeetingListSchema.validate(response.data.Results)
    return response.data;
};

export const fetchMeetingById = async (id: number) => {
    try {
        const response = await axios.get<Meeting>(`${CREATE_MEETING}?id=${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching timesheet by id:', error);
        throw error;
    }
};

export const deleteMeetingById = async (id: number) => {
    try {
        const response = await axios.delete(`${CREATE_MEETING}?id=${id}`);
        return response.data;
    } catch (error) {
        console.log("Error deleting meeting", error);
        throw error;
    }
}

export const createTripFromMeeting = async (data: { meetingId: number, orgId: number }) => {
    try {
        const response = await axios.post(`${CREATE_TRIP_FROM_MEETING}`, data);
        return response.data;
    } catch (error) {
        console.error("Error creating trip from meeting", error);
        throw error;
    }
}









