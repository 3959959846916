import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import { TPermissionAction, TPermissions, TToken } from './_models';
import * as authHelper from './AuthHelpers';
import { useLocation } from 'react-router-dom';
import { WithChildren } from '../../../@types/Utils';

type AuthContextProps = {
  auth: string | undefined;
  saveAuth: (auth: string | undefined) => void;
  currentUser: string | undefined;
  setCurrentUser: Dispatch<SetStateAction<string | undefined>>;
  allowIf: (permissions: TPermissions) => boolean;
  logout: () => void;
  permissions: TPermissions;
  id: number | undefined;
  permissionsMapped: any;
  setAuth: (d: string | undefined) => void;
};
type PermissionAction = {
  Read?: boolean;
  Write?: boolean;
  [key: string]: boolean | undefined; // To accommodate any additional action keys
};

type PermissionMap = {
  [key: string]: PermissionAction;
};
const initAuthContextPropsState = {
  auth: '',
  id: undefined,
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
  permissions: [],
  allowIf: () => false,
  setAuth: () => { },
  permissionsMapped: [],
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);
AuthContext.displayName = 'Auth';

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<string | undefined>(
    authHelper.getAuthToken(),
  );

  const [permissions, setPermissions] = useState<TPermissions>([]);
  const [permissionsMapped, setPermissionsMapped] = useState({});
  const [currentUser, setCurrentUser] = useState<string | undefined>();
  const [id, setId] = useState<number | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const parsedToken = authHelper.parseJwt<TToken>(auth as string);
    setPermissions((parsedToken?.permissions as TPermissions) || []);
    setCurrentUser(parsedToken?.email || undefined);
    const mappedPermissions: { [key: string]: { actions: { [action: string]: boolean } } } = {};

    parsedToken?.permissions.forEach(permission => {
      const [key, action] = permission.split('_'); // Split permission into key and action

      // Initialize the key in the mappedPermissions object if it doesn't exist
      if (!mappedPermissions[key]) {
        mappedPermissions[key] = { actions: {} };
      }

      // Set the action to true
      mappedPermissions[key].actions[action] = true;
    });

    setPermissionsMapped(convertPermissions(parsedToken?.permissions));
    setId(Number(parsedToken?.personId));
    setLoading(false)
  }, [auth]);
  const convertPermissions = (permissions: TPermissionAction[] | undefined): PermissionMap => {
    const permissionMap: PermissionMap = {};
    if (permissions)
      permissions.forEach(permission => {
        const [title, action] = permission.split('_');

        if (!permissionMap[title]) {
          permissionMap[title] = {}; // Initialize if not existing
        }

        // Set action to true
        if (action) {
          permissionMap[title][action] = true;
        } else {
          permissionMap[title] = {}; // If no action, just initialize
        }
      });

    return permissionMap;
  };
  const saveAuth = (auth: string | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const allowIf = (perms: TPermissions) => {
    return perms.reduce((allow, perm) => {
      return permissions.includes(perm) && allow;
    }, true);
  };

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
    setId(undefined);
    localStorage.setItem('currentUser', '');
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        permissions,
        id,
        allowIf,
        setAuth,
        permissionsMapped
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

//changes here so refresh doesnt logout.
const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, currentUser, logout } = useAuth();
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  const location = useLocation();

  //On route change if token is expired with logout user. We  don't want to logout user if he is on login page
  useEffect(() => {
    if (!auth) return;
    if (location.pathname.includes('auth')) return;

    const tokenIsExpired = authHelper.IsTokenExpired(auth);

    if (tokenIsExpired) logout();
  }, [location, auth, logout]);

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    if (auth) {
      if (currentUser) {
        logout();
      }
      setShowSplashScreen(false);
    } else {
      logout();
      setShowSplashScreen(false);
    }
  }, []);

  return showSplashScreen ? <></> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
