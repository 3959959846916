import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Tooltip } from 'antd';
import { isLightColour } from '../../utils/colourFunctions';
import { hashCode, intToRGB } from '../../utils/generateColors';
import { EmployeeWithAvatar } from '../../api-requests/Employees';

type Props = {
    cell: EmployeeWithAvatar[] | any;
};

const AvatarGroupCell: React.FC<Props> = ({ cell }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [maxVisibleAvatars, setMaxVisibleAvatars] = useState(4);
    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setMaxVisibleAvatars(3); // Adjust to ensure space for '+n' avatar
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const overflowCount = cell.length - maxVisibleAvatars;

    return (
        <div ref={containerRef} style={{ fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Avatar.Group size="small">
                {cell.slice(0, maxVisibleAvatars).map((cl: any, i: any) => (
                    <Tooltip title={cl.FullName} key={i}>
                        <Avatar size={"small"} style={{
                            backgroundColor: cl.FullName
                                ? '#' + intToRGB(hashCode(cl.FullName))
                                : '',
                            color: cl.FullName &&
                                isLightColour('#' + intToRGB(hashCode(cl.FullName)))
                                ? 'black'
                                : 'white',
                        }}>
                            {cl.FirstName[0]}{cl.LastName[0]}
                        </Avatar>
                    </Tooltip>
                ))}
                {overflowCount > 0 && (
                    <Tooltip title={
                        cell.slice(maxVisibleAvatars).map((c: any, i: any) => (
                            <div key={i} className="row text-left">
                                <span>{c.FullName}</span>
                            </div>
                        ))
                    }>
                        <Avatar className='bg-primary'>+{overflowCount}</Avatar>
                    </Tooltip>
                )}
            </Avatar.Group>
        </div>
    );
};

export default AvatarGroupCell;
